<template>
  <v-container>
    <v-row align="center">
      <v-col
        sm="8"
        md="8"
        lg="6"
        xl="4"
        class="mx-auto"
      >
        <img
          :src="require('../assets/logo.png')"
          height="30px"
          alt=""
        >
        <v-card
          class="mx-auto pa-4 pa-sm-6 pa-md-16 my-4"
          elevation="6"
          style="max-width: 600px;"
        >
          <v-row>
            <v-col>
              <h2 class="mb-2">
                Recupera la tua password
              </h2>
            </v-col>
          </v-row>
          <v-stepper
            v-model="step"
            flat
            alt-labels
          >
            <v-stepper-content step="1">
              <v-form
                ref="form"
                class="text-left"
                @submit.prevent="pswReminder"
              >
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.email"
                      dense
                      label="Email"
                      type="email"
                      required
                      outlined
                      autocomplete="off"
                      :rules="[formRules.required,formRules.emailRules]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      block
                      class="primary darken-1 white--text"
                      :loading="loading"
                      @click="pswReminder"
                    >
                      Richiedi
                    </v-btn>
                    <v-alert
                      v-if="error"
                      dense
                      outlined
                      color="error"
                      class="my-5"
                      dismissible
                    >
                      {{ error }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-row>
                <v-col>
                  <p>Mail inviata.</p>
                  <p>Una mail è appena stata inviata all'indirizzo email <strong>{{ user.email }}</strong>, verifica la tua posta in arrivo per completare il processo di recupero della password.</p>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="my-4">
      <v-col class="text-center">
        <v-btn
          text
          color="primary"
          :to="{name:'login'}"
        >
          Torna alla Home
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'PasswordReminder',
  data: () => ({
    user: {
      email: '',
    },
    error: null,
    loading: false,
    step: 1,
  }),
  computed: {
    ...mapGetters(['formRules']),
  },
  methods: {
    async pswReminder() {
      this.loading = true
      this.error = null

      if (this.$refs.form.validate()) {
        const resp = await this.$api.passwordReminder(this.user)
        if (resp.data.status === 'success') {
          this.step = 2
        } else {
          this.error = resp.data.message
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped>
.no-uppercase {
    text-transform: unset !important;
}
</style>
